<template>
    <section class="login container flex-center">
        <div class="row w-100 flex-center">

            <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center px-3 py-4 p-sm-5" 
                style="background-color: #FFFFFFFD; border-radius: 8px;">

                <span class="ge--description-sm">
                    <b>ADMINISTRADOR</b>
                </span>
                    
                <img :src="img_logo" class="img-fluid mb-2">

                <ge-input v-model="email" 
                    placeholder="Correo" 
                    class="mb-4"/>

                <ge-input v-model="password" 
                    placeholder="Contraseña" 
                    type="password" 
                    class="mb-5"/>

                <!-- <div class="flex-start-center pb-4">
                    <input type="checkbox" name=""> 
                    <div class="ps-2">
                        <span class="ge--description-xs">
                            <small><b>Recordarme</b></small>
                        </span>
                    </div>
                </div> -->

                <button @click="login()" 
                    class="btn btn-primary btn-pill w-100 mb-2">
                        <span class="ge--description-sm text-white">
                            Ingresar
                        </span>
                </button>

                <div class="text-start">
                    <span @click="$redirect('recovery-admin')" 
                        class="ge--description-xs text-primary cursor-pointer">
                            <small>
                                <b>¿Olvidaste tu contraseña?</b>
                            </small>
                    </span>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script setup>
    import { ref, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // Images
    const img_logo = ref(require('ASSETS/images/logo'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $redirect = inject('$redirect')

    // Computeds

    // Variables
    const email = ref(null)
    const password = ref(null)

    // Methods
    const login = async() => {
        store.dispatch('showLoading')

        let body = {
            email: email.value,
            password: password.value
        }

        let response = await $sendRequest('administrator/login', 'POST', body)
        // console.log(response, 'response login')

        if(response.success && response.data) {
            await store.dispatch('createSession', {
                session: response.data.session,
                type: 'administrator'
            })
            store.dispatch('showAlert', 'Bienvenido')
            store.dispatch('hiddenLoading')
            $redirect('dashboard-admin')
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_empty_email') {
                    message.push('El campo "Correo" es requerido')
                }
                else if(error.code == 'error_empty_password') {
                    message.push('El campo "Contraseña" es requerido')
                }
                else if(error.code == 'error_user_not_found') {
                    message.push('Usuario o contraseña incorrectos')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    // VueCicleLife
    
</script>

<style>
    .login {
        min-height: calc(100vh - 48px);
    }
</style>